// Generated by Framer (6b748fa)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Jmwvfx02x", "zhN02EmYn", "ss0zCb7FD", "hFiXK6qow", "P6Flq7aSS"];

const serializationHash = "framer-zdJwy"

const variantClassNames = {hFiXK6qow: "framer-v-fthr29", Jmwvfx02x: "framer-v-gpua12", P6Flq7aSS: "framer-v-hkcefx", ss0zCb7FD: "framer-v-6jxtyt", zhN02EmYn: "framer-v-n3k1ae"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Footer Logo": "ss0zCb7FD", "Main Logo": "Jmwvfx02x", "Phone Logo": "hFiXK6qow", "Phone Seondary Logo": "P6Flq7aSS", "Secondary Logo": "zhN02EmYn"}

const getProps = ({height, id, link, width, ...props}) => { return {...props, j5O95N1Qq: link ?? props.j5O95N1Qq, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Jmwvfx02x"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, j5O95N1Qq, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Jmwvfx02x", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={j5O95N1Qq} openInNewTab={false}><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 40, intrinsicWidth: 218, pixelHeight: 200, pixelWidth: 1080, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/uDBcNyz46DR0Wf8FUzdM9JNpZo.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/uDBcNyz46DR0Wf8FUzdM9JNpZo.png?scale-down-to=512 512w,https://framerusercontent.com/images/uDBcNyz46DR0Wf8FUzdM9JNpZo.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/uDBcNyz46DR0Wf8FUzdM9JNpZo.png 1080w"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-gpua12", className, classNames)} framer-1485o91`} data-framer-name={"Main Logo"} layoutDependency={layoutDependency} layoutId={"Jmwvfx02x"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({hFiXK6qow: {"data-framer-name": "Phone Logo", background: {alt: "", fit: "fill", intrinsicHeight: 40, intrinsicWidth: 39, pixelHeight: 400, pixelWidth: 400, src: "https://framerusercontent.com/images/dTt3tlXT9xSQuQTAXaMW4emsRjs.png"}}, P6Flq7aSS: {"data-framer-name": "Phone Seondary Logo", background: {alt: "", fit: "fill", intrinsicHeight: 40, intrinsicWidth: 39, pixelHeight: 200, pixelWidth: 39, src: "https://framerusercontent.com/images/bBV3ZSaK7xJr6jLcSSOIguipULw.svg"}}, ss0zCb7FD: {"data-framer-name": "Footer Logo", background: {alt: "", fit: "fill", intrinsicHeight: 40, intrinsicWidth: 218, pixelHeight: 200, pixelWidth: 1080, src: "https://framerusercontent.com/images/F3o9t9z2TFvOkJFhl9omO3MU.svg"}}, zhN02EmYn: {"data-framer-name": "Secondary Logo", background: {alt: "", fit: "fill", intrinsicHeight: 40, intrinsicWidth: 218, pixelHeight: 200, pixelWidth: 1080, src: "https://framerusercontent.com/images/77M5yW9Zd6osDFI46Cyw1ITXfY.svg"}}}, baseVariant, gestureVariant)}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zdJwy.framer-1485o91, .framer-zdJwy .framer-1485o91 { display: block; }", ".framer-zdJwy.framer-gpua12 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 16px; height: 40px; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: 216px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-zdJwy.framer-gpua12 { gap: 0px; } .framer-zdJwy.framer-gpua12 > * { margin: 0px; margin-left: calc(16px / 2); margin-right: calc(16px / 2); } .framer-zdJwy.framer-gpua12 > :first-child { margin-left: 0px; } .framer-zdJwy.framer-gpua12 > :last-child { margin-right: 0px; } }", ".framer-zdJwy.framer-v-fthr29.framer-gpua12, .framer-zdJwy.framer-v-hkcefx.framer-gpua12 { width: 40px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 216
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"zhN02EmYn":{"layout":["fixed","fixed"]},"ss0zCb7FD":{"layout":["fixed","fixed"]},"hFiXK6qow":{"layout":["fixed","fixed"]},"P6Flq7aSS":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"j5O95N1Qq":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerq1uYJxYb4: React.ComponentType<Props> = withCSS(Component, css, "framer-zdJwy") as typeof Component;
export default Framerq1uYJxYb4;

Framerq1uYJxYb4.displayName = "Logo";

Framerq1uYJxYb4.defaultProps = {height: 40, width: 216};

addPropertyControls(Framerq1uYJxYb4, {variant: {options: ["Jmwvfx02x", "zhN02EmYn", "ss0zCb7FD", "hFiXK6qow", "P6Flq7aSS"], optionTitles: ["Main Logo", "Secondary Logo", "Footer Logo", "Phone Logo", "Phone Seondary Logo"], title: "Variant", type: ControlType.Enum}, j5O95N1Qq: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framerq1uYJxYb4, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})